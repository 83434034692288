<template>
    <template v-if="dataGridControl?.menuTabs">
        <template v-if="horizontal">
            <template v-if="dataGridControl.menuTabs.activeTab === 'details'">
                <h5 v-if="!dataGridControl.props.detailIframe && !dataGridControl.dataObject" class="mb-0 p-2">{{detailsTitle}}</h5>
                <slot name="detailActions">
                    <template v-if="dataGridControl.props.detailIframe && dataGridControl.dataObject">
                        <div class="mx-auto pe-3">
                            <button class="btn btn-link btn-sm" :title="$t('Go to previous row')"
                                @click="dataGridControl.menuTabs?.navigateRow(true)" :disabled="dataGridControl.dataObject.currentIndex <= 0 || dataGridControl.dataObject.state.isLoading">
                                <i class="bi bi-chevron-left"></i>
                            </button>
                            <button class="btn btn-link btn-sm" :title="$t('Go to next row')"
                                @click="dataGridControl.menuTabs?.navigateRow()" 
                                :disabled="dataGridControl.dataObject.state.isRowCountLoading 
                                || (dataGridControl.dataObject.rowCount && (dataGridControl.dataObject.rowCount - 1) === dataGridControl.dataObject.currentIndex) 
                                || dataGridControl.dataObject.state.isLoading">
                                <i class="bi bi-chevron-right"></i>
                            </button>
                            <button class="btn btn-link btn-sm" :title="$t('Reload detail')"
                                @click="dataGridControl.menuTabs?.iframe?.contentWindow.location.reload()" :disabled="!dataGridControl.menuTabs?.iframe || dataGridControl.dataObject.state.isLoading">
                                <i class="bi bi-arrow-clockwise"></i>
                                {{ $t('Reload')}}
                            </button>
                        </div>
                    </template>
                </slot>
            </template>
            <template v-else-if="dataGridControl.menuTabs.activeTab === 'columns'">
                <h5 class="mb-0 p-2">{{$t('Column Layout')}}</h5>
                <LayoutActions v-if="dataGridControl.dataObject?.layoutManager" class="w-100" />
            </template>
            <template v-else-if="dataGridControl.menuTabs.activeTab === 'filters'">
                <h5 class="mb-0 p-2">{{$t('Filters')}}</h5>
                <div class="pt-1 ms-2" v-if="dataGridControl.dataObject?.filterObject?.activeFilter">
                    <span :title="$t('Active filter')">
                        {{ dataGridControl.dataObject?.filterObject?.activeFilter.FilterName}}
                    </span>
                </div>
               
            </template>
            <h5 v-else class="mb-0 p-2">{{currentTabTitle}}</h5>
        </template>
        <nav v-else class="menu-tabs-nav" :class="[{'menu-tabs-nav-left': left}, navClass]">
            <button
                @click="dataGridControl.menuTabs.expandMenu"
                class="btn btn-sm btn-link menu-tab-button" :title="$t('Collapse')">
                <template v-if="!left">
                    <i v-if="dataGridControl.menuTabs.sizerState === 'collapsedLeft'" class="bi bi-chevron-right"></i>
                    <i v-else class="bi bi-chevron-double-left"></i>
                </template>
                <template v-else>
                    <i v-if="dataGridControl.menuTabs.sizerState === 'collapsedRight'" class="bi bi-chevron-left"></i>
                    <i v-else class="bi bi-chevron-double-right"></i>
                </template>
            </button>
            <button
                @click="dataGridControl.menuTabs.expandGrid"
                class="btn btn-sm btn-link menu-tab-button" :title="$t('Collapse')">
                <template v-if="left">
                    <i v-if="dataGridControl.menuTabs.sizerState === 'collapsedLeft'" class="bi bi-chevron-right"></i>
                    <i v-else class="bi bi-chevron-double-left"></i>
                </template>
                <template v-else>
                    <i v-if="dataGridControl.menuTabs.sizerState === 'collapsedRight'" class="bi bi-chevron-left"></i>
                    <i v-else class="bi bi-chevron-double-right"></i>
                </template>
            </button>

            <button v-if="dataGridControl.menuTabs.detailsTabEnabled" class="btn btn-link btn-sm nav-link menu-tab-button"
                :class="{ 'active': dataGridControl.menuTabs.activeTab === 'details' && dataGridControl.menuTabs.visible }"
                @click="dataGridControl.menuTabs.setActiveTab('details', skipCollapse);" :title="detailTabTitle || ''">
                <div class="mb-1">
                    <i class="bi bi-card-text"></i>
                </div>
                <span class="menu-tab-text">{{ $t('Details') }}</span>
            </button>

            <button v-if="shouldShowMenuItem('filters')" class="btn btn-link btn-sm nav-link menu-tab-button"
                :class="{ 'active': dataGridControl.menuTabs.activeTab === 'filters' && dataGridControl.menuTabs.visible }"
                @click="dataGridControl.menuTabs.setActiveTab('filters', skipCollapse);" :title="$t('Field Filters')">
                <div class="mb-1">
                    <i class="bi bi-funnel"></i>
                </div>
                <span class="menu-tab-text">{{ $t('Filters') }}</span>
            </button>

            <button v-if="shouldShowMenuItem('columns')" class="btn btn-link btn-sm nav-link menu-tab-button"
                :class="{ 'active': dataGridControl.menuTabs.activeTab === 'columns' && dataGridControl.menuTabs.visible }"
                @click="dataGridControl.menuTabs.setActiveTab('columns', skipCollapse);" :title="$t('Column Chooser')" aria-current="page">
                <div class="mb-1">
                    <i class="bi bi-layout-three-columns"></i>
                </div>
                <span class="menu-tab-text">{{ $t('Column Layout') }}</span>
                <i v-if="layoutIsDirty" class="bi bi-circle-fill text-primary"
                    style="font-size: smaller;" :title="$t('Current layout has changes')"></i>
            </button>

            <button v-if="dataGridControl.dataObject && shouldShowMenuItem('export')" class="btn btn-link btn-sm nav-link menu-tab-button"
                :class="{ 'active': dataGridControl.menuTabs.activeTab === 'export' && dataGridControl.menuTabs.visible }"
                @click="dataGridControl.menuTabs.setActiveTab('export', skipCollapse);" :title="dataGridControl.importData ? $t('Export / Import') : $t('Export')">
                <div>
                    <i class="bi bi-database"></i>
                </div>
                <span class="menu-tab-text">
                    {{
                        dataGridControl.importData
                            ? $t('Export / Import')
                            : $t('Export')
                    }}
                </span>
            </button>

            <!-- <button v-if="dataGridControl.menuTabs.layoutsTabEnabled && shouldShowMenuItem('layouts')" class="btn btn-link btn-sm nav-link menu-tab-button" -->
                <!-- :class="{ 'active': dataGridControl.menuTabs.activeTab === 'layouts' && dataGridControl.menuTabs.visible }" -->
                <!-- @click="dataGridControl.menuTabs.setActiveTab('layouts', skipCollapse);" :title="$t('Layouts')"> -->
                <!-- <div class="mb-1"> -->
                    <!-- <i class="bi bi-grid-1x2"></i> -->
                <!-- </div> -->
                <!-- <span class="menu-tab-text">{{ $t('Layouts') }}</span> -->
            <!-- </button> -->

            <button v-if="dataGridControl.menuTabs.groupByFoldersTabEnabled" class="btn btn-link btn-sm nav-link menu-tab-button"
                :class="{ 'active': dataGridControl.menuTabs.activeTab === 'groupbyfolders' && dataGridControl.menuTabs.visible }"
                @click="dataGridControl.menuTabs.setActiveTab('groupbyfolders', skipCollapse);" :title="$t('Group By Folders')">
                <div class="mb-1">
                    <i class="bi bi-folder"></i>
                </div>
                <span class="menu-tab-text">{{ $t('Group By Folders') }}</span>
            </button>


        <!-- ======================================== -->

        <!-- <button -->
                <!-- @click="dataGridControl.menuTabs.switchSides" -->
                <!-- class="btn btn-sm btn-link menu-tab-button mt-auto" :title="$t(left ? 'Move grid panel to the right side' : 'Move grid panel to the left side')"> -->
                    <!-- <i v-if="left" class="bi bi-chevron-bar-right"></i> -->
                    <!-- <i v-else class="bi bi-chevron-bar-left"></i> -->
        <!-- </button> -->
        </nav>
    </template>
</template>
<script setup lang="ts">
import $t from 'o365.modules.translate.ts';
import type DataGridControl from 'o365.controls.DataGrid.ts';
import type { Ref } from 'vue';
import { dataGridControlKey } from 'o365.modules.vue.injectionKeys.js';
import { inject, computed, ref } from 'vue';

import useAsyncComponent from 'o365.vue.composables.AsyncComponent.ts';
const LayoutActions = useAsyncComponent('o365.vue.compoents.DataGrid.LayoutActions.vue');

const props = withDefaults(defineProps<{
    navClass?: string;
    skipCollapse?: boolean;
    left?: boolean;
    detailTabTitle?: string;
    horizontal?: boolean;
}>(), {
    navClass: 'nav flex-column bg-light-subtle border-start border-end',
    skipCollapse: false
});

const dataGridControl: Ref<DataGridControl> = inject(dataGridControlKey, null);

function shouldShowMenuItem(pItem: string) {
    if (dataGridControl.value.props.hideMenuItems == null) {
        return true;
    } else {
        return !dataGridControl.value.props.hideMenuItems.includes(pItem);
    }
}

const currentTabTitle = computed(() => {
    switch(dataGridControl?.value.menuTabs?.activeTab) {
        case 'filters':
            return $t('Filters');
        case 'columns':
            return $t('Column Layout');
        case 'export':
            return dataGridControl.value.importData
                ? $t('Export / Import')
                : $t('Export');
        case 'layouts':
            return $t('Layouts');
        case 'groupbyfolders':
            return $t('Group By Folders');
        default:
            return '';
    }
});

const detailsTitle = computed(() => {
    if (dataGridControl.value.props.menuTabs && dataGridControl.value.props.menuTabs.length > 0) {
        return $t('Details');
    } else {
        return dataGridControl.value.props.detailTabTitle ?? $t('Details')
    }
});

const layoutIsDirty = computed(() => {
    const layoutManager = dataGridControl.value.dataObject && dataGridControl.value.dataObject?.layoutManager;
    if (layoutManager == null || layoutManager.isSaving || !layoutManager.canSave) {
        return false;
    }
    const activeLayout = layoutManager.hasActiveLayout
        ? dataGridControl.value.dataObject?.layoutManager.activeLayout
        : null;
    return activeLayout && activeLayout.hasChanges();
});

</script>

<style scoped>
.menu-tabs-nav {
    flex-wrap: nowrap;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: none;
    margin-right: 2px;
    min-width: calc(2rem + 2px);
    max-width: calc(2rem + 2px);
}

.menu-tabs-nav-left {
    margin-left: 2px;
    margin-right: unset;
}

.menu-tabs-nav::-webkit-scrollbar {
    display: none;
}

.menu-tab-button.active {
    background-color: rgba(var(--bs-primary-rgb), .1);
    border-right: 2px solid rgba(var(--bs-primary-rgb), .5);
    color: rgb(var(--bs-primary-rgb));
    font-weight: 500;
}
.menu-tabs-nav-left .menu-tab-button.active {
    border-left: 2px solid rgba(var(--bs-primary-rgb), .5);
    border-right: unset;
}

.menu-tab-button {
    border-radius: 0px;
    padding-left: 0.25rem;
    padding-right: 0rem;
    width: 2rem;
}

.menu-tab-button:hover {
    background-color: rgba(var(--bs-primary-rgb), .15);
}

.menu-tab-text {
    writing-mode: vertical-lr;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
</style>